import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const icons = {
  search: (
    <svg viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  share: (
    <svg viewBox="0 0 32 32">
      <path d="M8 20c0 0 1.838-6 12-6v6l12-8-12-8v6c-8 0-12 4.99-12 10zM22 24h-18v-12h3.934c0.315-0.372 0.654-0.729 1.015-1.068 1.374-1.287 3.018-2.27 4.879-2.932h-13.827v20h26v-8.395l-4 2.667v1.728z" />
    </svg>
  ),
  envelope: (
    <svg viewBox="0 0 20 20">
      <path d="M18 2c1.105 0 2 0.895 2 2v0 12c0 1.105-0.895 2-2 2v0h-16c-1.105 0-2-0.895-2-2v0-12c0-1.1 0.9-2 2-2h16zM13.63 11.1l6.37 4.9v-2l-5.12-3.9 5.12-4.1v-2l-10 8-10-8v2l5.12 4.1-5.12 3.9v2l6.37-4.9 3.63 2.9 3.63-2.9z" />
    </svg>
  ),
  load: (
    <svg viewBox="0 0 32 32">
      <path d="M12 4c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM20.485 7.515c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM26 16c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.485 24.485c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM14 28c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM5.515 24.485c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM4.515 7.515c0 0 0 0 0 0 0-1.657 1.343-3 3-3s3 1.343 3 3c0 0 0 0 0 0 0 1.657-1.343 3-3 3s-3-1.343-3-3zM1.75 16c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25c0 1.243-1.007 2.25-2.25 2.25s-2.25-1.007-2.25-2.25z" />
    </svg>
  ),
  chevronRight: (
    <svg viewBox="0 0 16 16">
      <path d="M5.5 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />
    </svg>
  ),
  chevronLeft: (
    <svg viewBox="0 0 16 16">
      <path d="M10.5 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
    </svg>
  ),
  bookmark: (
    <svg viewBox="0 0 32 32">
      <path d="M6 0v32l10-10 10 10v-32z" />
    </svg>
  ),
  map: (
    <svg viewBox="0 0 28 28">
      <title>map</title>
      <path d="M8 0c0.266 0 0.5 0.234 0.5 0.5v23c0 0.187-0.109 0.359-0.266 0.438l-7.5 4c-0.078 0.047-0.156 0.063-0.234 0.063-0.266 0-0.5-0.234-0.5-0.5v-23c0-0.187 0.109-0.359 0.266-0.438l7.5-4c0.078-0.047 0.156-0.063 0.234-0.063zM27.5 0c0.266 0 0.5 0.234 0.5 0.5v23c0 0.187-0.109 0.359-0.266 0.438l-7.5 4c-0.078 0.047-0.156 0.063-0.234 0.063-0.266 0-0.5-0.234-0.5-0.5v-23c0-0.187 0.109-0.359 0.266-0.438l7.5-4c0.078-0.047 0.156-0.063 0.234-0.063zM10 0c0.078 0 0.156 0.016 0.219 0.047l8 4c0.172 0.094 0.281 0.266 0.281 0.453v23c0 0.266-0.234 0.5-0.5 0.5-0.078 0-0.156-0.016-0.219-0.047l-8-4c-0.172-0.094-0.281-0.266-0.281-0.453v-23c0-0.266 0.234-0.5 0.5-0.5z" />
    </svg>
  ),
  pin: (
    <svg viewBox="0 0 16 16">
      <path d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 9 5 9s5-4 5-9c0-2.761-2.239-5-5-5zM8 8.063c-1.691 0-3.063-1.371-3.063-3.063s1.371-3.063 3.063-3.063 3.063 1.371 3.063 3.063-1.371 3.063-3.063 3.063zM6.063 5c0 1.070 0.867 1.938 1.938 1.938s1.938-0.867 1.938-1.938-0.867-1.938-1.938-1.938c-1.070 0-1.938 0.867-1.938 1.938zM12.285 10.9c-0.222 0.348-0.451 0.678-0.682 0.988 0.037 0.017 0.073 0.035 0.108 0.052 0.76 0.38 1.101 0.806 1.101 1.059s-0.34 0.679-1.101 1.059c-0.957 0.479-2.31 0.753-3.712 0.753s-2.754-0.275-3.712-0.753c-0.76-0.38-1.101-0.806-1.101-1.059s0.34-0.679 1.101-1.059c0.036-0.018 0.072-0.035 0.108-0.052-0.231-0.31-0.461-0.64-0.682-0.988-1.061 0.541-1.715 1.282-1.715 2.1 0 1.657 2.686 3 6 3s6-1.343 6-3c0-0.817-0.654-1.558-1.715-2.1z" />
    </svg>
  ),
  expand: (
    <svg viewBox="0 0 20 20">
      <path d="M7.854 7.146l-6.146-6.146h3.793c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-5c-0.276 0-0.5 0.224-0.5 0.5v5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-3.793l6.146 6.146c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707z" />
      <path d="M19.5 0h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h3.793l-6.146 6.146c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.353 0.146s0.256-0.049 0.353-0.146l6.147-6.146v3.793c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-5c0-0.276-0.224-0.5-0.5-0.5z" />
      <path d="M7.854 12.147c-0.195-0.195-0.512-0.195-0.707 0l-6.146 6.146v-3.793c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v5c0 0.276 0.224 0.5 0.5 0.5h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3.793l6.146-6.147c0.195-0.195 0.195-0.512 0-0.707z" />
      <path d="M19.5 14c-0.276 0-0.5 0.224-0.5 0.5v3.793l-6.147-6.146c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l6.147 6.146h-3.793c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h5c0.276 0 0.5-0.224 0.5-0.5v-5c0-0.276-0.224-0.5-0.5-0.5z" />
    </svg>
  ),
  contract: (
    <svg viewBox="0 0 20 20">
      <path d="M7.5 3c-0.276 0-0.5 0.224-0.5 0.5v2.793l-6.146-6.146c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l6.146 6.146h-2.793c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h4c0.276 0 0.5-0.224 0.5-0.5v-4c0-0.276-0.224-0.5-0.5-0.5z" />
      <path d="M19.853 0.146c-0.195-0.195-0.512-0.195-0.707 0l-6.146 6.146v-2.793c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v4c0 0.276 0.224 0.5 0.5 0.5h4c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-2.793l6.146-6.146c0.195-0.195 0.195-0.512 0-0.707z" />
      <path d="M7.5 12h-4c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h2.793l-6.146 6.147c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.147l6.146-6.146v2.793c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-4c0-0.276-0.224-0.5-0.5-0.5z" />
      <path d="M19.853 19.147l-6.146-6.147h2.793c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-4c-0.276 0-0.5 0.224-0.5 0.5v4c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-2.793l6.147 6.147c0.098 0.098 0.226 0.146 0.353 0.146s0.256-0.049 0.353-0.147c0.195-0.195 0.195-0.512 0-0.707z" />
    </svg>
  ),
  ladestrom: (
    <svg viewBox="0 0 48 48">
      <circle fill="#E9510E" cx="24" cy="24" r="23" />
      <path
        fill="#FFFFFF"
        d="M38.9,15.51c0.73,0.57,1.1,1.3,1.1,2.19V19h2v10h-2l0,1.27c0,0.58-0.15,1.16-0.5,1.62
          C38.93,32.63,38.2,33,37.32,33l-28.6,0c-0.58,0-1.16-0.15-1.62-0.51C6.37,31.92,6,31.19,6,30.3l0-12.57c0-0.58,0.15-1.16,0.5-1.62
          C7.07,15.37,7.8,15,8.68,15l28.6,0C37.86,15,38.44,15.15,38.9,15.51z M9,18v12h28v-3h2v-6h-2v-3H9z M12,28v-8h5v8H12z"
      />
    </svg>
  ),
  lng: (
    <svg viewBox="0 0 48 48">
      <circle fill="#007D97" cx="24" cy="24" r="23" />
      <path fill="#FFFFFF" d="M6.22,31.02V16.98h3.45v10.97h5.9v3.08H6.22z" />
      <path
        fill="#FFFFFF"
        d="M25.54,31.02l-4.5-7.06v7.06h-3.45V16.98h3.02l4.5,7.06v-7.06h3.45v14.04H25.54z"
      />
      <path
        fill="#FFFFFF"
        d="M40.36,29.56c-1.2,1.24-2.54,1.58-4.08,1.58c-1.64,0-2.88-0.55-3.91-1.58c-1.48-1.48-1.42-3.45-1.42-5.56
          s-0.06-4.08,1.42-5.56c1.03-1.03,2.21-1.58,3.91-1.58c3.61,0,5.15,2.35,5.48,4.65h-3.47c-0.28-1.06-0.83-1.58-2.01-1.58
          c-0.63,0-1.1,0.28-1.36,0.61C34.6,20.96,34.4,21.44,34.4,24s0.2,3.06,0.51,3.47c0.26,0.33,0.73,0.59,1.36,0.59
          c0.73,0,1.22-0.22,1.54-0.55c0.39-0.39,0.53-0.97,0.53-1.5v-0.2h-2.07v-2.88h5.5v1.95C41.78,27.16,41.42,28.48,40.36,29.56z"
      />
    </svg>
  ),
  landstrom: (
    <svg viewBox="0 0 48 48">
      <circle fill="#FCEE21" cx="24" cy="24" r="23" />
      <path
        d="M24.03,35.5c-5.41,0-10.82,0-16.23,0c-0.15,0-0.31-0.01-0.46-0.01c-0.58,0.02-1.14-0.56-0.94-1.34c0.03-0.12,0.09-0.23,0.15-0.34
        c5.45-9.5,10.89-18.99,16.34-28.49c0.09-0.17,0.19-0.33,0.32-0.47c0.51-0.53,1.27-0.45,1.67,0.17c0.16,0.25,14.85,25.79,16.51,28.66
        c0.17,0.29,0.27,0.59,0.25,0.93c-0.03,0.48-0.38,0.84-0.86,0.88c-0.18,0.01-0.36,0.02-0.55,0.02C34.83,35.5,29.43,35.5,24.03,35.5z
        M23.99,8.26L9.83,32.93l28.32-0.06L23.99,8.26z"
      />
      <path
        d="M22.54,31.5c-0.21-1.14-0.42-2.29-0.63-3.44c0.02-0.01,0.04-0.02,0.06-0.03c0.27,0.29,0.53,0.58,0.84,0.92c0.25-1.01,0.44-1.99,0.66-2.96
        c0.22-0.97,0.42-1.94,0.65-2.97c-1.53,0.58-3,1.13-4.49,1.69c-0.06-0.13,0.01-0.22,0.04-0.31c0.55-1.51,1.09-3.02,1.64-4.53
        c0.61-1.67,1.22-3.34,1.82-5.01c0.02-0.04,0.04-0.09,0.05-0.13c0.03-0.15,0.13-0.19,0.27-0.19c0.33,0.01,0.67,0,1.01,0.01
        c0.49,0,0.98,0.01,1.46,0.02c0.07,0,0.15,0.02,0.27,0.03c-0.31,0.67-0.66,1.29-0.99,1.92c-0.33,0.65-0.68,1.29-1.01,1.93
        c-0.33,0.64-0.67,1.27-1,1.91c-0.33,0.63-0.66,1.27-0.97,1.92c1.63-0.68,3.23-1.42,4.83-2.12c0.03,0.02,0.03,0.03,0.04,0.03
        c0,0.01,0.01,0.02,0.01,0.03c0,0.02,0,0.04-0.01,0.06c-1.09,2.97-2.19,5.94-3.3,8.95c0.44-0.05,0.83-0.2,1.23-0.26c0.02,0.03,0.03,0.03,0.03,0.04
        c0,0.01,0.01,0.02,0,0.03c-0.76,0.77-1.51,1.55-2.27,2.32c-0.05,0.05-0.11,0.11-0.17,0.15C22.61,31.5,22.59,31.5,22.54,31.5z"
      />
    </svg>
  ),
  lavspent: (
    <svg viewBox="0 0 48 48">
      <circle fill="#008B4D" cx="24" cy="24" r="23" />
      <path
        fill="#FFFFFF"
        d="M16.63,41.5c-0.18,0-0.36-0.05-0.52-0.16c-0.37-0.25-0.5-0.73-0.32-1.13l5.97-13.44l-8.8,0
          c-0.25,0-0.49-0.09-0.66-0.27c-0.37-0.39-0.35-0.96,0-1.31L30.7,6.79c0.21-0.21,0.51-0.33,0.8-0.27
          c0.64,0.12,0.94,0.76,0.71,1.29l-5.97,13.44l8.8,0c0.25,0,0.49,0.09,0.66,0.27c0.37,0.39,0.35,0.96,0,1.31
          L17.29,41.23C17.11,41.41,16.87,41.5,16.63,41.5L16.63,41.5zM15.05,25.15h8c0.18,0,0.35,0.04,0.5,0.14
          c0.41,0.26,0.54,0.76,0.36,1.16l-4.54,10.21l13.36-13.36l-8,0c-0.18,0-0.35-0.04-0.5-0.14
          c-0.41-0.26-0.54-0.76-0.36-1.16L28.4,11.8L15.05,25.15z"
      />
    </svg>
  ),
  ukjent: (
    <svg viewBox="0 0 48 48">
      <circle fill="#666666" cx="24" cy="24" r="23" />
      <path
        fill="#FFFFFF"
        d="M16.63,41.5c-0.18,0-0.36-0.05-0.52-0.16c-0.37-0.25-0.5-0.73-0.32-1.13l5.97-13.44l-8.8,0
          c-0.25,0-0.49-0.09-0.66-0.27c-0.37-0.39-0.35-0.96,0-1.31L30.7,6.79c0.21-0.21,0.51-0.33,0.8-0.27
          c0.64,0.12,0.94,0.76,0.71,1.29l-5.97,13.44l8.8,0c0.25,0,0.49,0.09,0.66,0.27c0.37,0.39,0.35,0.96,0,1.31
          L17.29,41.23C17.11,41.41,16.87,41.5,16.63,41.5L16.63,41.5zM15.05,25.15h8c0.18,0,0.35,0.04,0.5,0.14
          c0.41,0.26,0.54,0.76,0.36,1.16l-4.54,10.21l13.36-13.36l-8,0c-0.18,0-0.35-0.04-0.5-0.14
          c-0.41-0.26-0.54-0.76-0.36-1.16L28.4,11.8L15.05,25.15z"
      />
    </svg>
  ),
  metanol: (
    <svg viewBox="0 0 48 48">
      <circle fill="#B6DCFF" cx="24" cy="24" r="23" />
      <path d="M31.37,11.68h5.05v24.63h-5.67l-0.03-11.28l-4.67,11.28h-4.12l-4.71-11.14v11.14h-5.64V11.68h5.09l7.37,16.82L31.37,11.68z" />
    </svg>
  )
};

const Icon = props => {
  const { name } = props;
  return <span className="icon">{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
