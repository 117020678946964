import en from "./en";
import no from "./no";

import config from "../config/kystverket";

const translations = { en, no };

export function ts(category, string) {
  const tr = config.site === 4 ? no : en;
  return (tr[category] && tr[category][string]) || false;
}

export default translations;
