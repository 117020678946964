import React from "react";
import PropTypes from "prop-types";
import { WmsLayer, LayerSwitch, LayerGroup, XYZLayer } from "react-openlayers";
import { translate } from "react-translate";

const MapLayers = ({ t }) => (
  <LayerSwitch tooltip={t("selectMap")}>
    <LayerGroup
      id="basemap_group"
      singleSelect
      disabledLayers={["sjokart", "enc", "Norge_i_bilder"]}
    >
      <XYZLayer
        id="graatone"
        key="graatone"
        name={t("baseMap")}
        url="https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png"
        zIndex={-1}
      />

      <WmsLayer
        id="sjokart"
        key="sjokart"
        name={t("seaMap")}
        uri={["https://wms.geonorge.no/skwms1/wms.sjokartraster2"]}
        layerName="all"
        version="1.3.0"
        zIndex={-1}
      />
      <WmsLayer
        id="enc"
        key="enc"
        name={t("enc")}
        layerName="cells"
        version="1.1.1"
        zIndex={-2}
        useBaat
        uri="https://kart.kystverket.no/proxies/xEncProxy.ashx?"
      />
      <XYZLayer
        id="Norge_i_bilder"
        key="Norge_i_bilder"
        name={t("norwayInPictures")}
        urls={[
          "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
          "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
          "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        ]}
        zIndex={-1}
        useBaat
      />
    </LayerGroup>
  </LayerSwitch>
);

MapLayers.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate("MapLayers")(MapLayers);
