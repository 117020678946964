import React from "react";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
import Article from "../components/Article";
import ArticleAdditionalColumn from "../components/article/ArticleAdditionalColumn";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ArticleWindow, ensureArticleModuleConfig } from "react-article-module";
import { provideMapState } from "react-openlayers";
import config from "../config/kystverket";

import "./ArticleDetailView.scss";

const mapStateToProps = (state) => ({
  activeSources: state.articles.activeSources,
  lastMapCenter: state.mapHistory.lastMapCenter,
  lastZoomLevel: state.mapHistory.lastZoomLevel,
});

export class ArticleDetailView extends React.Component {
  constructor() {
    super();
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { history, activeSources } = this.props;
    const prefix = config.site === 4 ? "/anlegg" : "/en/facilities";
    history.push(
      prefix +
        (activeSources && activeSources.length
          ? "/" + activeSources.join("+")
          : "")
    );
  }

  render() {
    const {
      match: {
        params: { sourceId, articleId },
      },
    } = this.props;
    const aid = sourceId + "_" + articleId;

    return (
      <Modal onClose={this.onClose}>
        <div className="article-detail-view--container">
          <ArticleWindow
            id={aid}
            maxZoom={16}
            articleComponent={Article}
            additionalColumnComponent={ArticleAdditionalColumn}
            hideOpenExternalBtn
            disableZoom
          />
        </div>
      </Modal>
    );
  }
}

ArticleDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  activeSources: PropTypes.string,
  history: PropTypes.object.isRequired,
  fitViewAndZoom: PropTypes.func.isRequired,
  lastMapCenter: PropTypes.array,
  lastZoomLevel: PropTypes.number,
};

export default connect(mapStateToProps)(
  provideMapState(ensureArticleModuleConfig(withRouter(ArticleDetailView)))
);
