import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import FullScreenButton from "../components/FullScreenButton";
import { MapPadding } from "react-openlayers";
import { translate } from "react-translate";
import { provideViewSize } from "../hocs/provideViewSize";

import "./CoreLayout.scss";

export class CoreLayout extends React.Component {
  constructor(props) {
    super(props);

    if (window.innerWidth > 768) {
      this.state = {
        fullscreen: false,
        fullscreenAvailable: true,
      };
    } else {
      this.state = {
        fullscreen: false,
        fullscreenAvailable: false,
      };
    }
    this.onFullScreenBtnClick = this.onFullScreenBtnClick.bind(this);
  }
  static propTypes = {
    children: PropTypes.any,
    viewWidth: PropTypes.number,
    viewHeight: PropTypes.number,
    t: PropTypes.func.isRequired,
  };

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [50, 0, (6 * viewHeight) / 10, 0];
    }
    if (viewWidth < 1260) {
      return [70, 400, 0, 0];
    }
    return [70, 440, 0, 0];
  }

  componentWillUpdate() {
    const { viewWidth } = this.props;
    if (viewWidth < 769 && this.state.fullscreenAvailable) {
      this.setState({ fullscreenAvailable: false });
    }
    if (viewWidth > 769 && !this.state.fullscreenAvailable) {
      this.setState({ fullscreenAvailable: true });
    }
  }

  toggleFullscreen() {
    let elements = document.querySelectorAll([
      ".@avinet/react-openlayers--show-location",
      ".layer-switch--button-container",
      ".layer-switch--container",
      ".fullscreen",
      ".ol-zoom",
    ]);

    elements.forEach((element) => {
      element.classList.toggle("fullscreen--on");
    });
  }

  onFullScreenBtnClick() {
    const { fullscreen } = this.state;

    this.toggleFullscreen();

    this.setState({ fullscreen: !fullscreen });
  }

  render() {
    const { children, t } = this.props;
    const { fullscreenAvailable, fullscreen } = this.state;
    return (
      <div className="core-layout--root">
        {fullscreenAvailable && (
          <FullScreenButton
            fullscreen={fullscreen}
            onClick={this.onFullScreenBtnClick}
            tooltip={t("fullscreenTip")}
          />
        )}
        <MapPadding padding={this.calcMapPadding()} />
        <NavBar
          fullscreen={fullscreen}
          fullscreenToggle={this.onFullScreenBtnClick}
        />
        <div
          className="core-layout--components"
          style={fullscreen ? { display: "none" } : {}}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default provideViewSize(translate("CoreLayout")(CoreLayout));
