import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import createStore from "./redux/createStore";
import { injectReducer } from "./redux/reducers";
import App from "./containers/App";
import config from "./config/kystverket";
import { CmsConfig } from "react-adaptive-cms";
import "./index.scss";

config.site = 4;
if (
  window.location.pathname.length > 1 &&
  window.location.pathname.split("/")[1] === "en"
) {
  config.site = 5;

  try {
    document.getElementsByTagName("html")[0].setAttribute("lang", "en");
  } catch (ex) {}
}

CmsConfig.init(config.adaptiveUrl, config.site);

const initState = window.__INITIAL_STATE__;
const store = createStore(initState);

export function addReducerToStore(key, reducer) {
  injectReducer(store, { key, reducer });
}

ReactDOM.render(<App store={store} />, document.getElementById("root"));
