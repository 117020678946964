import React from "react";
import PropTypes from "prop-types";
import SearchResult from "./SearchResult";
import { withRouter } from "react-router-dom";
import { Search as ArticleSearch } from "react-article-module";
import config from "../config/kystverket";
import "./Search.scss";

const onResultSelected = (history) => (result, source) => {
  history.push(
    (config.site === 4 ? "/anlegg/" : "/en/facilities/") +
      [source.dataIndex, result.id.split("_").slice(1).join("_")].join("/")
  );
};

const Search = ({ history, placeholder }) => (
  <ArticleSearch
    placeholder={placeholder}
    onResultSelected={onResultSelected(history)}
    searchResult={SearchResult}
  />
);

Search.propTypes = {
  history: PropTypes.object,
  placeholder: PropTypes.string,
};

export default withRouter(Search);
