import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Nav, Link, navActions } from "react-adaptive-cms";
import config from "../config/kystverket";
import logo from "../static/logo.png";
import "./NavBar.scss";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, navActions), dispatch);
};

const mapStateToProps = (state) => ({
  nav: state.nav.nav,
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
    };
  }

  componentDidMount() {
    const { loadNav } = this.props;
    loadNav();
  }

  get navItems() {
    const { nav } = this.props;
    const items = nav.map((item) => ({
      ...item,
      menu_uri: (config.site === 5 ? "en/" : "") + item.menu_uri,
    }));
    items.push(this.langItem);
    return items;
  }

  get langItem() {
    if (config.site === 4) return { menu_uri: "en", title: "English" };
    return { menu_uri: "/", title: "Norsk" };
  }

  get homeLink() {
    return config.site === 5 ? "/en" : "/";
  }

  render() {
    const { fullscreenToggle, fullscreen } = this.props;
    const { navOpen } = this.state;

    return (
      <div className="nav-bar--container">
        <Link to={this.homeLink} className="nav-bar--header-image">
          <img src={logo} alt="Kystverket" />
        </Link>

        <div
          className={[
            "nav-bar--menu",
            navOpen ? " nav-bar--menu-active" : "",
          ].join(" ")}
        >
          <input
            type="checkbox"
            className="nav-bar--hamburger"
            checked={navOpen}
            onChange={() => {}}
            onClick={() =>
              this.setState({ navOpen: !navOpen, profileOpen: false })
            }
          />
          <span />
          <span />
          <span />
          <Nav
            nav={this.navItems}
            onNavClick={() => {
              this.setState({ navOpen: false, profileOpen: false });
              fullscreen && fullscreenToggle();
            }}
          />
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  nav: PropTypes.array,
  loadNav: PropTypes.func,
  fullscreen: PropTypes.bool,
  fullscreenToggle: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
