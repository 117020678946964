import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { TranslatorProvider } from "react-translate";
import translations from "../translations/translations";
import {
  translations as reactArticleModuleTranslations,
  ArticleModule,
} from "react-article-module";
import { addReducerToStore } from "../index";
import config from "../config/kystverket";
import SourceConfig, { SourceIndexes } from "../components/SourceConfig";
import ArticleLayers from "../components/ArticleLayers";
import Router from "../routes";
import "./App.scss";
import MapLayout from "../layouts/MapLayout";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layers: Object.values(SourceIndexes),
    };
  }

  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  onSearchInit(reducer) {
    addReducerToStore("searchSources", reducer);
  }

  getTranslations(lang) {
    return Object.assign(
      translations[lang],
      reactArticleModuleTranslations[lang]
    );
  }

  render() {
    const { store } = this.props;
    const { layers } = this.state;

    return (
      <Provider store={store}>
        <TranslatorProvider
          translations={this.getTranslations(config.site === 4 ? "no" : "en")}
        >
          <MapLayout layers={layers}>
            <ArticleModule
              adaptiveUrl={config.adaptiveUrl}
              articleModuleUuid={config.articleModuleUuid}
              onSearchInit={this.onSearchInit}
            >
              <SourceConfig />
              <Router>
                <ArticleLayers layers={layers} />
              </Router>
            </ArticleModule>
          </MapLayout>
        </TranslatorProvider>
      </Provider>
    );
  }
}

export default App;
